import { setUrl } from "./helper";

const disciplineButtons = document.querySelectorAll(
	"#disciplines-filtering .DisciplineBox"
);
const container = document.querySelector("#discipline-output");

const getDisciplinePayload = () => {
	const searchParams = new URLSearchParams(window.location.search);
	return {
		discipline_id: searchParams.get("discipline") || 0,
	};
};

const filterDisciplines = async () => {
	const payload = getDisciplinePayload();
	const action = "ajaxFilterProductsByDiscipline";

	const request = await fetch(
		`${jsData.ajaxUrl}?action=${action}&discipline=${payload.discipline_id}`,
		{
			method: "GET",
		}
	);

	const { data, success } = await request.json();

	if (!success) return;

	if (data.message) {
		return (container.innerHTML = `<p class="Text Text--center">${data.message}</p>`);
	}

	container.innerHTML = `
		<div class="Grid Grid--list Archive__grid">
		${data.posts.map((item) => renderItem(item)).join("")}
		</div>
		`;
};

const handleFilterDisciplines = (ev) => {
	ev.preventDefault();

	setUrl(ev.currentTarget.href);

	filterDisciplines();
};

const renderItem = ({ link, title, thumbnail }) => {
	return `
	<div class="Card">
		<a class="Card__image" href="${link}">
			${thumbnail}
		</a>
		<div class="Card__content">
			<a href="${link}">
				<h2 class="Card__title">
					${title}
				</h2>
			</a>
		</div>
	</div>
	`;
};

disciplineButtons.forEach((button) =>
	button.addEventListener("click", handleFilterDisciplines)
);

/**
 *  Filter Disciplines PDF
 */

const buttons = document.querySelectorAll(
	"#disciplines-pdf-filter .DisciplineBox"
);
const btns = document.querySelectorAll(".DisciplineBox");

if (btns) {
	btns.forEach((btn) => {
		btn.addEventListener("click", () => {
			btns.forEach((btn) => {
				btn.classList.remove("current-discipline");
			});

			btn.classList.add("current-discipline");

			const body = document.querySelector("body");
			const disciplineName = document.querySelector("#selected-discipline");
			if (disciplineName) {
				disciplineName.innerHTML = btn.textContent;
			}
		});
	});
}

const filterDisciplinesPDF = async (pdf = "") => {
	const payload = getDisciplinePayload();
	const action = "ajaxFilterDisciplinesPDF";

	const request = await fetch(
		`${jsData.ajaxUrl}?action=${action}&discipline=${payload.discipline_id}&pdf=${pdf}`,
		{
			method: "GET",
		}
	);

	const { data, success } = await request.json();

	if (!success) return;

	document.querySelector("#disciplinePDF").innerHTML = `${data}`;
	if (data) {
		jQuery(".pdfemb-viewer").pdfEmbedder();
	}
};

const handleFilterDisciplinesPDF = (ev) => {
	ev.preventDefault();
	setUrl(ev.currentTarget.href);

	const pdf = ev.currentTarget.getAttribute("data-pdf");
	filterDisciplinesPDF(pdf);
};

buttons.forEach((button) =>
	button.addEventListener("click", handleFilterDisciplinesPDF)
);
