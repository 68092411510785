const popup = document.querySelector(".FormPopup");
const closePopup = document.querySelector(".meeting__close");
const popupToggleButton = document.querySelector("#meeting-button");

export const togglePopup = () => {
  popup.classList.toggle("FormPopup--active");
};

popupToggleButton?.addEventListener("click", togglePopup);

closePopup?.addEventListener("click", togglePopup);

popup?.addEventListener("click", togglePopup);

document.querySelector(".FormPopup__content") &&
  document
    .querySelector(".FormPopup__content")
    .addEventListener("click", (ev) => {
      ev.stopPropagation();
    });
