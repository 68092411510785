import FloatLabels from "./forms/float-labels";
import "./FrontPageHeroSlider";
import { toggleMenu } from "./navigation";
import { toggleSearchPopup } from "./search-popup";
import "./Discipline";
import "./career-faqs";
import "./CareerPopup";
import "./meetingPopup";
import "./product-request";
import "./slider-block";
import "./landingFix";
// import "./references-filtering";
import { gsap, Power3 } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

window.addEventListener("load", () => {
  new FloatLabels();

  toggleMenu();

  const closeSearchPopupBtn = document.querySelector("#searchPopupCloseBtn");
  const searchIcon = document.querySelector(".Header__searchBtn");
  searchIcon?.addEventListener("click", toggleSearchPopup);
  closeSearchPopupBtn?.addEventListener("click", toggleSearchPopup);

  const counterItems = document.querySelectorAll("[data-counter]");

  counterItems.forEach((counter) => {
    gsap.to(counter, {
      textContent: counter.getAttribute("data-counter"),
      duration: 3,
      scrollTrigger: counter,
      ease: Power3.easeOut,
      snap: { textContent: 1 },
    });
  });
});
