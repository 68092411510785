window.onload = () => {
  const body = document.querySelector("body");
  if (body.classList.contains("page-id-2214")) {
    const productsDiv = document.querySelector(".Archive__grid");
    if (productsDiv) {
      const parent = productsDiv.parentNode;
      const container = document.createElement("div");

      container.classList.add("Container");

      parent.replaceChild(container, productsDiv);
      container.appendChild(productsDiv);
    }
  }
};
